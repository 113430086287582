import {Component} from 'react';

class LeopardStaticUIConfig extends Component {
    static GridView_ShowBorder = true;
    static GridView_CacheEnabled = false;
    static GridView_AllowEditing = true;
    static GridView_EditingMode = "row";
    static GridView_AllowColumnResizing = true;
    static GridView_ColumnResizingMode = "widget";
    static GridView_ExplicitFilterOption = "auto";
    static GridView_ColumnMinWidth = 50;
    static GridView_ColumnAutoWidth = true;
    static GridView_RepaintChangesOnly = true;
    static GridView_HighlightChanges = true;
    static GridView_AllowColumnReordering = true;
    static GridView_HoverStateEnabled = true;
    static GridView_GroupingEnabled = true;
    static GridView_RowAlternationEnabled = true;
    static GridView_FilterRow = true;
    static GridView_DefaultFilterValue = "";
    static ColumnChooser_Enabled = false;
    static Selection_Mode = "multiple";
    static LoadPanel_ShowPane = false;
    static LoadPanel_ShowIndicator = false;
    static Selection_SelectAllMode = "page";
    static Selection_ShowCheckBoxesMode = "onLongTap";
    static Sorting_Mode = "multiple";
    static HeaderFilter_Visible = true;
    static ColumnFixing_Enabled = true;
    static RemoteOperations_Sorting = true;
    static RemoteOperations_Paging = true;
    static RemoteOperations_Summary = true;
    static RemoteOperations_Grouping = true;
    static RemoteOperations_GroupPaging = true;
    static RemoteOperations_Filtering = true;
    static Pager_allowedPageSizes = [10, 25, 50];
    static FilterOperationsForString = ["contains", "notcontains", "startswith", "endswith", "=", "<>"];
    static FilterOperationsForNonString = ["=", "<>", "<", ">", "<=", ">=", "between"];
    static FilterOperationsForBoolean = ["=", "<>"];
    static StateStoring_Enabled = false;

    static LeopardDashboardColorBars = [
        "#4BAE4F", "#3E50B4", "#785447", "#F34235",
        "#2195F2", "#FE9700", "#8AC249", "#FE5622",
        "#02A8F3", "#CCDB39", "#E81D62", "#9B27AF",
        "#00BBD3", "#FEEA3B", "#6639B6", "#009587",
        "#FEC007", "#5F7C8A"];

    static StateStoring_Type = "localStorage";
    static ODataDocUrl = "http://docs.oasis-open.org/odata/odata/v4.0/errata03/os/complete/part2-url-conventions/odata-v4.0-errata03-os-part2-url-conventions-complete.html#_Toc453752357";

    static ReportViewerAppearanceShowTooltipsHelp = false;
    static ReportViewerToolbarShowAboutButton = false;
    static ReportViewerToolbarShowFileMenuAbout = false;
    static ReportViewerToolbarShowFileMenuHelp = false;
    static ReportViewerToolbarShowFileMenuInfo = false;
    static ReportDesignerAppearanceShowTooltipsHelp = false;
    static ReportDesignerToolbarShowDesignButton = true;
    static ReportDesignerToolbarShowSaveButton = false;
    static ReportDesignerToolbarShowAboutButton = false;
    static ReportDesignerToolbarShowOpenButton = false;
    static ReportLicenseKey = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHlenmdXNzxXDz0ngghkojJSfv7kMIOdo17IcnGaPBpGSTxzvZ" +
        "f6LX84OB9H5QpTs8ZSPCqa0+kftCjm/7atgoI0l/9p27haruFSHvodHgTH0tI02f6zcKNNSAVKzynQiVmVk/JtHgoO" +
        "0O/g/t7oZ4lyxbSqKDfppbrzkDBRvtNA9aF6kT5IJiwvfLs/PMi5XD8dcPYA5UPw7JXFYXNV7eM8i69QbfFqCNfWuV" +
        "vLdcqdQ4exhMyJzn7YfZVz0QrS3ViKesMPmd76KdhO/VuoMabvFhU8oqBSCUwU9tntlBX6bEvmqBVfDuV5aAunyyeK" +
        "DrAhZ2e+tJ+GHccejhgVKI26JMeVxgwCf3VngTvVaffwLVo2G2ZemJd33jV3RrruIDaaeEC/WljNnG1ulaYtnD1VOd" +
        "cJT1O5KPoEuJyuUgePYwZvO1Ez8HIqiiUcVDIdYw2u8vmm20mxzgYoKGMY4uKI53yd6+g2FiW4S4QeRqaQQNxcBxi/" +
        "ZUVEJc/HGCC9vBtNBJpwFVdcHqO5BKpwlRRR2M+xyhov7zzW71rj1Lr9IA==";

    static CssVersion = 23;
    static GridViewConfigVersion = 1;
    static ChartConfigVersion = 1;
    static DashboardConfigVersion = 1;
    static DataViewConfigVersion = 1;
    static LeftMenuItemVersion = 1;

    static ControlCentreVersion = "1.5.1.34";
    static ControlCentreReleaseTime = "2019-11-28 04:16:53";
    static ControlCentreReleaseBranch = "hi-trans-1.7";
    static ControlCentreCommitID = "94ed578f36e28a169d4831edcdc589b8e6b406d2";

    static Global_GridViewCurrentEditRowData = null;
    static Global_PopupCustomColumnData = null;
    static Global_DashboardDataViewListeners = [];
    static Global_DashboardAutoSelectByDefault = true;
    static Global_DashboardAutoSelectInstance = null;
    static Global_TempDateConvertion = [];

    static StaticList_CommandButtonList = [{
        value: "modify-row",
        text: "Modify Row"
    }, {
        value: "add-row",
        text: "Add Row"
    }, {
        value: "delete-row",
        text: "Delete Row"
    }];

    static TooltipAnimationConfig = {
        show: {
            type: 'pop',
            from: {scale: 0},
            to: {scale: 1},
            delay: 1000
        },
        hide: null
    };

    static GridViewDefaultDefinition = {
        columnDefinition: [],
        adaptiveColumn: false,
        allowEditing: false,
        allowDragging: false,
        allowDropping: false,
        enableAutoRefresh: false,
        autoRefreshInterval: 30,
        customColumnOverallWidth: 200,
        showAutoRefreshSwitch: true,
        optimizePagerForLargeDataset: false,
        defaultPageSize: 10,
        explicitFilterOption: LeopardStaticUIConfig.GridView_ExplicitFilterOption,
        filterBuilderValue: LeopardStaticUIConfig.GridView_DefaultFilterValue,
        gridViewDefinitionVersion: LeopardStaticUIConfig.GridViewConfigVersion,
        columnResizingMode: LeopardStaticUIConfig.GridView_ColumnResizingMode
    };

    static ChartDefaultDefinition = {
        argAxisAllowDecimals: true,
        argAxisCustomizeText: undefined,
        argAxisEnableAutoBreak: false,
        argAxisEndOnTick: false,
        argAxisInvertAxis: false,
        argAxisMaxAutoBreakCount: 4,
        argAxisMaxValueMargin: undefined,
        argAxisPosition: "bottom",
        argAxisShowZero: true,
        argAxisType: undefined,
        argAxisValueType: "string",
        argAxisOverlappingBehavior: "stagger",
        chartAllowMouseWheel: true,
        chartAllowTouchGuestures: true,
        chartBarGroupPadding: 0.3,
        chartBarGroupWitdh: undefined,
        chartBgColorForExport: "#FFFFFF",
        chartContainerBgColor: "#FFFFFF",
        chartDragToZoom: false,
        chartEnableExport: false,
        chartMarginForExport: 10,
        chartMaxBubbleSize: 0.2,
        chartMinBubbleSize: 12,
        chartNegativeValuesAsZeroes: false,
        chartPaletteExtMode: "blend",
        chartPointSelectionMode: "single",
        chartRedrawOnResize: true,
        chartResolveLabelOverlapping: "none",
        chartScrollbarOffset: 5,
        chartScrollbarPosition: "bottom",
        chartSeriesSelectionMode: "single",
        chartShowScrollbar: false,
        labelAlignment: "left",
        labelArgumentFormat: undefined,
        labelBgColor: undefined,
        labelHorizontalOffset: 0,
        labelLabelFormat: undefined,
        labelPosition: "outside",
        labelRotationAngle: 0,
        labelShowForZero: true,
        labelVerticalOffset: 0,
        labelVisibility: true,
        legendBgColor: undefined,
        legendColumnCount: undefined,
        legendColumnItemSpacing: 20,
        legendHorizontalAlignment: "left",
        legendHoverMode: "includePoints",
        legendItemTextPosition: "right",
        legendItemsAlignment: "left",
        legendMargin: 10,
        legendMarkerSize: 20,
        legendOrientation: "horizontal",
        legendPaddingLeftRight: 10,
        legendPaddingTopBottom: 10,
        legendPosition: "outside",
        legendRowCount: undefined,
        legendRowItemSpacing: 8,
        legendVerticalAlignment: "bottom",
        legendVisibility: true,
        pointColor: undefined,
        pointCustomSymbol: "circle",
        pointHoverMode: "onlyPoint",
        pointSelectionMode: "onlyPoint",
        pointSizeOfPoint: 12,
        pointVisibility: true,
        seriesAxisBinding: undefined,
        seriesHoverMode: "onlyPoint",
        seriesIgnoreEmptyPoint: true,
        seriesSelectionMode: "onlyPoint",
        seriesStyleBarPadding: undefined,
        seriesStyleBarWidth: undefined,
        seriesStyleCornerRadius: 0,
        seriesStyleDashStyle: "solid",
        seriesStyleInnerColor: undefined,
        tooltipArgumentFormat: undefined,
        tooltipArrowLength: 10,
        tooltipColor: "#FFFFFF",
        tooltipEnabled: false,
        tooltipLocation: "center",
        tooltipOpacity: undefined,
        tooltipPaddingLeftRight: 10,
        tooltipPaddingTopBottom: 10,
        valueAxisAllowDecimals: true,
        valueAxisCustomizeText: undefined,
        valueAxisEnableAutoBreak: false,
        valueAxisEndOnTick: false,
        valueAxisInvertAxis: false,
        valueAxisMaxAutoBreakCount: 4,
        valueAxisMaxValueMargin: undefined,
        valueAxisPosition: "left",
        valueAxisShowZero: true,
        valueAxisType: undefined,
        valueAxisValueType: "numeric",
        valueAxisHighAverage: undefined,
        valueAxisHighAverageText: "High Average",
        valueAxisLowAverage: undefined,
        valueAxisLowAverageText: "Low Average",
        seriesSmallValueGroupingMode: "topN",
        seriesSmallValueGroupingTopCount: 8,
        chartPalette: "Soft Pastel",
        chartShowCrosshair: false,
        defaultVisualRange: 20,
        chartCrosshairWidth: 2,
        chartCrosshairColor: "#FE8000",
        chartCrosshairStyle: "solid",
        chartTitleFontSize: 20,
        enableAutoRefresh: false,
        autoRefreshInterval: 30,
        customColumnOverallWidth: 200,
        showAutoRefreshSwitch: true,
        chartDefinitionVersion: LeopardStaticUIConfig.ChartConfigVersion
    };
}

export default LeopardStaticUIConfig;